import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './App.css';

const Rating = ({ ratingMessages }) => {
  const [rating, setRating] = useState(5);
  const [inProp, setInProp] = useState(false);

  const incrementRating = () => {
    if (rating < 10) {
      setInProp(true);
      setRating(rating + 1);
    }
  };

  const decrementRating = () => {
    if (rating > 1) {
      setInProp(true);
      setRating(rating - 1);
    }
  };

  return (
    <div>
        <div className="rating-container">
        <button className="rating-button" onClick={decrementRating}>-</button>
        <CSSTransition in={inProp} timeout={100} classNames="rating-value-change" onEntered={() => setInProp(false)}>
            <span className="rating-value">{rating}</span>
        </CSSTransition>
        <button className="rating-button" onClick={incrementRating}>+</button>
        </div>
        <div className="rating-message">{ratingMessages[rating - 1]}</div>
    </div>
  );
};

export default Rating;