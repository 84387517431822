import Rating from './Rating';
import './App.css';

function App() {
  const ratingMessages = [
    "Was the meeting in Klingon? Because I understood nothing.",       // 1
    "A moment of silence for the time we'll never get back.",         // 2
    "Like a mystery novel where the plot was too complicated.",       // 3
    "The human equivalent of a software update at 3am.",              // 4
    "It had its moments, none of them particularly good.",            // 5
    "An average gathering of average minds.",                         // 6
    "Not all heroes wear capes, some just end meetings on time.",     // 7
    "Could've been an email, but at least you tried.",                // 8
    "So good, even the office plants wanted to attend.",              // 9
    "The unicorn of meetings: mythical, but surprisingly real."       // 10
  ];

  return (
    <div className="app-container">
      <h1>1parkplace EOS Meeting Rater</h1>
      <Rating ratingMessages={ratingMessages}/>
    </div>
  );
}

export default App;